import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import News from '../components/sections/News';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import GenericSection from '../components/sections/GenericSection';
import FeaturesSplitOS from '../components/sections/FeaturesSplitOS';
import SectionHeader from '../components/sections/partials/SectionHeader';


class Home extends React.Component {
  
  render() {

    const sectionHeader = {
      title: 'Welcome back. We exist to make entrepreneurship easier.',
    };

    return (
      <React.Fragment>
        <HeroSplit invertColor hasBgColor/>
        <GenericSection >
          <div className="container-s">
            <h2 className="mt-0">Background</h2>
            <p>
            In the realm of neurocritical care, where patients are often fighting for their lives following traumatic brain injuries, strokes, or other serious conditions, the assessment of consciousness is a crucial part of their treatment and prognosis. Neurologists, nurses, and other healthcare providers rely on frequent neurological exams to determine the level of consciousness in these patients. However, the current methods employed in these assessments, particularly those involving noxious stimuli, can cause significant harm to patients, leading to complications, extended hospital stays, and strained relationships between caregivers and patients' families.
            </p>
            <h4>The Typical Process</h4>
            <p>
            When a patient is admitted to the Neuro-ICU, a non-injurious neurologic exam is performed, in which a clinician assesses factors like eye movement, speaking patterns, and reflexes to better understand the ever-changing state of the patient's nervous system. If the patient is responsive, the clinician will develop a patient-specific treatment plan, following from the results of the exam. However, if the patient is not responsive, a more comprehensive assessment of consciousness is required. 
            </p>
            <p>
            The primary goal of this consciousness assessment is to observe how a patient reacts to external stimuli. A clinician will typically start with a mild noxious stimulus, such as a light pinch on the arm or a firm press on the nail bed, in order to trigger an involuntary motor response. If the patient is still unresponsive, the clinician will move forward with increasingly painful stimuli — potentially culminating in pinching the patient's tricep with as much force as possible, smashing the patient's fingernails, or administering an often damaging sternal rub. The patient's response (or lack thereof) to these stimuli provides insight into the state of the patient's consciousness, which is notoriously difficult to measure non-invasively.
            </p>
            <h4>The Problem</h4>
            <p>
            Unfortunately, the exams don't stop there. Neuro-ICUs have varying protocols, but for all of them, this consciousness assessment is administered by a doctor, registered nurse, or nurse practitioner frequently throughout a patient's stay. When our team shadowed at the JHMI Neuro-ICU, we discovered these exams were typically administered by nurses during their rounds once an hour. For stroke patients, they are administered once every fifteen minutes. The frequency of these exams, in combination with their physically harmful nature, can lead to an accumulation of bruises and cuts. It can be particularly distressing for families to see their loved ones sustain new injuries throughout the duration of their stay.
            </p>
          </div>
        </GenericSection>   
        <FeaturesTabs  topDivider bottomDivider />
        <GenericSection >
          <div className="center-content">
            <h2 className="mt-0">Our Solution</h2>
          </div>
        </GenericSection>   
        <FeaturesSplitOS/>
        <GenericSection >
         <div className="container-s center-content">
            <h4 className="mt-0">
            We will be the first device on the market to provide a non-harmful, controllable, and standardized pain stimulus, eliminating patient injury and providing physicians with a consistent basis for decision-making.
            </h4>
          </div>
        </GenericSection>   
        <FeaturesTiles topDivider/>
        <Roadmap hasBgColor invertColor/>

      </React.Fragment>
    );
  }
}

export default Home;